<template>
  <div class="pb-3">
    <!--        <v-layout row class="pb-3">-->
    <!--            <v-flex xs3>-->

    <!--                <v-text-field-->
    <!--                        hide-details-->
    <!--                        solo-->
    <!--                        label="Search comment"-->
    <!--                        append-icon="search"-->
    <!--                >-->
    <!--                </v-text-field>-->
 
    <!--            </v-flex>-->
    <!--        </v-layout>-->

    <v-layout row>
      <v-flex xs3>
        <v-text-field
          v-model="search"
          hide-details
          solo
          label="Search comment"
          append-icon="search"
          @input="searchInput"
        />
      </v-flex>
    </v-layout>

    <v-layout row justify-space-between>
      <v-flex xs12 sm6>
        <v-btn-toggle class="table-top-menu">
          <v-btn :to="{ name: 'published_comments' }" flat large>
            Published comments
          </v-btn>

          <v-btn :to="{ name: 'deleted_comments' }" flat large>
            Deleted comments
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "UserListTopNav",
  data: () => ({
    inputSearchCommentTimer: null,
    search: null,
    sortBy: { name: "Sort by registration date", value: "created_at" }
  }),
  created() {
    this.$bus.on("new-users-count", data => {
      this.newUsersCount = data
    })
  },
  destroyed() {
    this.$bus.off("new-users-count", data => {
      this.newUsersCount = data
    })
  },
  mounted() {
    // this.$bus.emit("user-sort-by", this.sortBy.value);
  },
  methods: {
    userSortBy() {
      this.$bus.emit("user-sort-by", this.sortBy.value)
    },
    searchInput() {
      clearTimeout(this.inputSearchCommentTimer)
      this.inputSearchCommentTimer = setTimeout(() => {
        this.$bus.emit("comment-search-input", this.search)
      }, 2000)
    }
  }
}
</script>

<style scoped>
.table-top-menu {
  width: 100%;
}

.table-top-menu .v-btn {
  height: 48px;
  flex-grow: 1;
  position: relative;
}

.v-btn.v-btn--active {
  pointer-events: none;
}
.count-new-users {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #f44336;
  border-radius: 50%;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
  transform: translateY(-50%);
}
</style>
